const accHeader = $('.homepage-accordion-item-title');
const accCont = $('.homepage-accordion-item-content');

accHeader.each(function(header) {
 $(this).on("mouseenter", function() {
   const accItem = $(this).parent()
   const accThisCont = accItem.find('.homepage-accordion-item-content');

   if(accCont.hasClass('active')) {
     accCont.removeClass('active')
   }
   accThisCont.toggleClass('active');


   if(accCont.parent().hasClass('active-item')) {
    accCont.parent().removeClass('active-item')
  }
  accThisCont.parent().toggleClass('active-item');
   
   if(accThisCont.hasClass('active')) {
    accThisCont.css({
      'maxHeight': accThisCont.prop('scrollHeight') +'px'
    })
   } else {
     accThisCont.css({
       'maxHeight': 0
     })
   }
 })
})

accCont.first().addClass('active');
accCont.first().parent().addClass('active-item');
